<template>
	<div class="animated fadeIn">
		<div class="container">
			<user-data-form
				:form-import="form"
				:create-new-user="true"
				:errors-import="errors.errors"
				:statuses="statuses"
				:countries="countries"
				:access-groups="accessGroups"
				@requestData="requestNewUser" />
		</div>
	</div>
</template>
<script>

import User from '@/util/User';
import Country from '@/util/Country';
import { STATUSES as statuses } from '@/settings/Statuses';
import { ACCESS_GROUPS as accessGroups } from '@/settings/Permissions';
import { Validations as messages } from '@/translations';
import UserDataForm from './components/UserDataForm';

const countries = new Country();
export default {
	name: 'NewUser',
	messages,
	components: {
		UserDataForm,
	},
	data() {
		return {
			corporate: new User(),
			statuses,
			countries: [],
			accessGroups,
			form: {
				username: '',
				email: '',
				first_name: '',
				last_name: '',
				status: 'active',
				access_group: 'manual',
				type: 'corporate',
				country: null,
				mobile_number: '',
				password: '',
				password_confirmation: '',
			},
			alert: new this.$Alert(),
		};
	},
	computed: {
		errors() {
			return this.corporate.data.errors;
		},
	},
	created() {
		countries.getCountries().then((response) => {
			this.countries = response;
		});
	},
	mounted() {
		this.setFocus('username');
	},
	methods: {
		requestNewUser(payload) {
			this.corporate.createUser(payload).then(() => {
				try {
					this.alert.toast('success', this.translate('corporate_created'));
				} finally {
					this.$router.push({ name: 'Corporates' });
				}
			}).catch(() => { this.data = payload; });
		},
	},
};
</script>
