export const ACCESS_GROUPS = {
	admin: 'admin',
	senior_executive: 'senior_executive',
	marketing_director: 'marketing_director',
	marketing_junior: 'marketing_junior',
	community_manager: 'community_manager',
	content_creator: 'content_creator',
	financial_director: 'financial_director',
	financial_junior: 'financial_junior',
	legal_director: 'legal_director',
	legal_junior: 'legal_junior',
	logistics_director: 'logistics_director',
	logistics_junior: 'logistics_junior',
	cs_director: 'cs_director',
	cs_supervisor: 'cs_supervisor',
	cs_agent: 'cs_agent',
	read_only: 'read_only',
	manual: 'manual',
};

export default {};
